export const setCookie = (cookieName, cookieValue, hourToExpire) => {
  const domain = window.location.hostname === 'localhost'
                                              ? 'localhost'
                                              : window.location.hostname.match('.skemeet.dev')
                                                ? '.skemeet.dev'
                                                : '.skemeet.io'
  ;
  let date = new Date();
  date.setTime(date.getTime()+(hourToExpire*60*60*1000));
  document.cookie = `${cookieName}=${cookieValue}; domain=${domain}; path=/; expires=${date.toGMTString()}`;
}

export const getCookie = (cookieName) => {
  var result = document.cookie.match(new RegExp(cookieName + '=([^;]+)'));
  result && (result = JSON.parse(result[1]));
  return result;
}

export const deleteCookie = (cookieName) => {
  const domain = window.location.hostname === 'localhost'
                                              ? 'localhost'
                                              : window.location.hostname.match('.skemeet.dev')
                                                ? '.skemeet.dev'
                                                : '.skemeet.io'
  ;
  document.cookie = `${cookieName}=; domain=${domain}; path=/; expires=Tue, 01 Aug 1980 10:00:00 GMT;`;
}
