import { apiUrl, apiSymfony6Url, authUrl, privateSitePrefix  } from './Config'

export default (() => {
  const fetching = async (path, params, errorMessage) => {
    params.credentials = 'include';

    try {
      const response = await fetch(path, params)

      if (403 === response.status) {
        isLogged()
      }

      if (404 === response.status) {
        throw Error('Not found')
      }

      if (!response.ok) {
        throw Error('')
      }

      const json = await response.json()
      return json
    } catch (e) {
      if (e.message === 'Not found')
        throw new Error('Not found')
      throw new Error(errorMessage)
    }
  }

  return {
    post({ path, errorMessage, body = {}, signal=null, isSymfony6 = false }) {
      return fetching(`${isSymfony6 ? apiSymfony6Url : apiUrl}${path}`, {
        method: 'POST',
        signal,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body,
      },
        errorMessage);
    },
    get({ path, errorMessage, signal = null, isSymfony6 = false }) {
      const params = {
        method: 'GET',
        signal,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
      const response = fetching(
        `${isSymfony6 ? apiSymfony6Url : apiUrl}${path}`,
        Object.entries(params)
          .filter(([key, value]) => value)
          .reduce((acc, [key, value]) => ({
            ...acc,
            [key]: value
          }), {}),
        errorMessage
      );
      return response
    },
    patch({ path, errorMessage, body = null, isSymfony6 = false }) {
      return fetching(`${isSymfony6 ? apiSymfony6Url : apiUrl}${path}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body,
      },
        errorMessage);
    },
    put({ path, errorMessage, body = null, isSymfony6 = false }) {
      return fetching(`${isSymfony6 ? apiSymfony6Url : apiUrl}${path}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body,
      },
        errorMessage);
    },
    delete({ path, errorMessage, body = null, isSymfony6 = false }) {
      return fetching(`${isSymfony6 ? apiSymfony6Url : apiUrl}${path}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body,
      },
        errorMessage);
    },
  }
})()

const isLogged = async function () {
  if (window.location.pathname.includes(`${privateSitePrefix}/`)) {
    try {
        const response = await fetch(`${apiUrl}/users/is-logged`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include',
        })

        if (403 === response.status) {
          window.location = authUrl
        }
    } catch {
      window.location = authUrl
    }
  }
}
