export const routeToUrlRedirectionWords = (route) => {
  return route.replace(/\//g, 'slash').replace(/\?/g, 'interrogationPoint').replace(/=/g, 'equal').replace(/&/g, '%26')
}

export const urlRedirectionWordsToRoute = (urlRedirectionWords) => {
  return urlRedirectionWords.replace(/slash/g, '/').replace(/interrogationPoint/g, '?').replace(/equal/g, '=').replace(/%26/g, '&')
}

export const removeURLParameter = (url, parameter) => {
  var urlparts = url.split('?');

  if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      for (var i = pars.length; i-- > 0;) {
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
              pars.splice(i, 1);
          }
      }

      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }

  return url;
}
