import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import * as Login from '../State/Login'
import * as Ui from './Ui'
import * as Styled from './Registration.styled'
import RegistrationForm from './Ui/RegistrationForm'
import SEOTags from './SEOTags';
import { redirectToAuth } from '../Util/Auth'
import { privateSitePrefix } from '../Util/Config'

const RegistrationComponent = (props) => {
  const loading = useSelector(Login.selectLoading)
  const visioConference = useSelector(Login.selectedVisioconference);

  useEffect(() => {
    redirectToAuth(
      `${process.env.REACT_APP_PRIVATE_URL}${privateSitePrefix}/patients`,
      visioConference,
      'subscription'
    );
  }, [])

  return (
    <>
      <SEOTags
        title="Inscrivez-vous - Skemeet"
      />
      <Styled.RegistrationTeleExpertiseContainer>
        <Ui.PortailLayout.Wrapper>
          <Ui.PortailHeader fromRegistrationPage noButtons />
          <Ui.PortailLayout.PortalMain $loading={loading}>
            {loading
              ? <Ui.Global.Loader />
              : <>
                <Ui.PortailLayout.PortalContainer>
                  <Ui.PortailLayout.PortalContentContainer>
                    <Ui.PortailLayout.FlexPage>
                      <Ui.PortailLayout.Content>
                        <RegistrationForm {...props} />
                      </Ui.PortailLayout.Content>
                    </Ui.PortailLayout.FlexPage>
                  </Ui.PortailLayout.PortalContentContainer>
                  <Ui.PortailLayout.Footer />
                </Ui.PortailLayout.PortalContainer>
              </>
            }
          </Ui.PortailLayout.PortalMain>
        </Ui.PortailLayout.Wrapper>
      </Styled.RegistrationTeleExpertiseContainer>
    </>
  )
}

export default RegistrationComponent;
