import * as Form from '../Util/Form'
import { privateSitePrefix } from '../Util/Config'
import * as Api from '../Util/Api'
import { routeToUrlRedirectionWords, removeURLParameter } from '../Util/Url'
import * as Eff from 'redux-saga/effects'
import * as Login from '../State/Login'
import * as MessageList from '../State/MessageList'
import * as Patient from '../State/Patient'
import { TOTP } from 'jsotp'
import qs from 'qs'
import { getCookie } from '../Util/Storage'

export function* redirectToPrivateServer (installTotp = false, redirectUri = null) {
  let path = `${privateSitePrefix}/patients`

  if (redirectUri) {
    // Homepage and portal redirection
    path = redirectUri
  }

  if (installTotp) {
    try {
      const { email } = yield Eff.call(Api.getSecretHandle)

      localStorage.setItem(email + '-hasTotp', true)

      path = `${privateSitePrefix}/installation-totp/${routeToUrlRedirectionWords(path)}`
    } catch (error) {
      yield Eff.put(Login.error(error.message))
    }
  }

  window.location.href = process.env.REACT_APP_PRIVATE_URL + path
}

export const getLocalStorageItem = (key = '') => localStorage.getItem(key)
export const getSessionStorageItem = (key = '') => sessionStorage.getItem(key)
export const getCookieItem = (key = '') => getCookie(key)

export const generateOtp = secret =>
  TOTP(secret.split('/').shift()).now()

export function* checkCode() {
  try {
    const code = yield Eff.select(Login.selectOtpCode)

    yield Eff.call(Form.validate, { code })

    const info = yield Eff.select(Login.selectOtpInfo)

    yield Eff.call(Api.sendOTPCode, { code: code.value, ...info })

    const installTotp = yield Eff.select(Login.selectInstallTotp)
    const visioConference = yield Eff.select(Login.selectedVisioconference)
    const patientCookie = yield Eff.call(getCookieItem, 'patient')
    const teleExpertiseCookie = yield Eff.call(getCookieItem, 'teleExpertise')
    const patient = patientCookie && Object.values(patientCookie).length > 0
      ? patientCookie
      : null;
    const expertise = teleExpertiseCookie && Object.values(teleExpertiseCookie).length > 0
      ? teleExpertiseCookie
      : null;

    yield Eff.call(redirectToPrivateServer, installTotp,
      patient && expertise
        ? `${privateSitePrefix}/creer-patient?${expertise.type}=${expertise.id}&ref=home&firstname=${patient.firstname}&lastname=${patient.lastname}&birthdate=${patient.birthdate}&sex=${patient.sex}`
        : visioConference?.id
          ? `${privateSitePrefix}/videoconference/${visioConference.type}s/${visioConference.id}`
          : null)

  } catch (error) {
    if (error instanceof Form.ValidationError) {
      const actions = Object
        .entries(error.data)
        .map(([name, { errors }]) => Login.invalidateOtpCode({
          errors,
        }))

      for (let action of actions) {
        yield Eff.put(action)
      }
    } else {
      yield Eff.put(Login.error(error.message))
    }
  }
}

export function* resendCode() {
  try {
    const otpInfo = yield Eff.select(Login.selectOtpInfo)

    yield Eff.call(Api.sendOTPDemand, otpInfo)

    yield Eff.put(Login.setCodeResent())
  } catch (error) {
    yield Eff.put(Login.error(error.message))
  }
}

export function* resetPassword() {
  try {
    const email = yield Eff.select(Login.selectEmail)
    yield Eff.call(Api.resetPassword, email.value)
    yield Eff.put(Login.resetPasswordSuccess())
  } catch (error) {
    yield Eff.put(Login.error(error.message))
  }
}

export function* sendCredentials() {
  try {
    const credentials = yield Eff.select(Login.selectCredentials)

    yield Eff.call(Form.validate, credentials)

    let {
      mode,
      directLogin,
    } = yield Eff.call(
      Api.authenticate,
      Object
        .entries(credentials)
        .reduce((acc, [name, field]) => ({
          ...acc,
          [name]: field.value,
        }), credentials)
      ,
    )

    yield Eff.put(Login.fetchOtpMode(mode))

    if (directLogin) {
      try {
        const visioConference = yield Eff.select(Login.selectedVisioconference)

        const patientCookie = yield Eff.call(getCookieItem, 'patient')
        const teleExpertiseCookie = yield Eff.call(getCookieItem, 'teleExpertise')
        const patient = patientCookie && Object.values(patientCookie).length > 0
          ? patientCookie
          : null;
        const expertise = teleExpertiseCookie && Object.values(teleExpertiseCookie).length > 0
          ? teleExpertiseCookie
          : null;

        // yield Eff.call(Form.validate, credentials)
        yield Eff.call(redirectToPrivateServer, false,
          patient && expertise
            ? `${privateSitePrefix}/creer-patient?${expertise.type}=${expertise.id}&ref=home&firstname=${patient.firstname}&lastname=${patient.lastname}&birthdate=${patient.birthdate}&sex=${patient.sex}`
            : visioConference.id
              ? `${privateSitePrefix}/videoconference/${visioConference.type}s/${visioConference.id}`
              : null)

      } catch (error) {
        yield Eff.call(Api.sendOTPDemand, {
          email: credentials.email.value,
          mode,
        })

        yield Eff.put(Login.updateOtpInfo({
          email: credentials.email.value,
          mode,
        }))
      }
    } else {
      yield Eff.put(Login.setCanInstallTotp(true))

      yield Eff.call(Api.sendOTPDemand, {
        email: credentials.email.value,
        mode,
      })

      yield Eff.put(Login.updateOtpInfo({
        email: credentials.email.value,
        mode,
      }))
    }
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      const actions = Object
        .entries(error.data)
        .map(([name, { errors }]) => Login.invalidateCredential({
          name,
          errors,
        }))

      for (let action of actions) {
        yield Eff.put(action)
      }
    } else if (/dernier essai/.test(error.message)) {
      yield Eff.put(Login.oneMoreLoginTry(true))
      yield Eff.put(Login.error(error.message))
    } else if (/Votre accès a été suspendu/.test(error.message)) {
      // yield Eff.call(Api.sendUserBlockedToAdmin)
      yield Eff.put(Login.error(error.message))
    } else {
      yield Eff.put(Login.error(error.message))
    }
  }
}

export function* fetchCurrentUser({ payload: { accessTokenFromSkezi } }) {
  try {
    if (accessTokenFromSkezi) {
      yield Eff.call(Api.checkAccessTokenFromSkezi, accessTokenFromSkezi)
      window.location.href = removeURLParameter(removeURLParameter(window.location.href, 'refreshToken'), 'accessToken')
    }
    const currentUser = yield Eff.call(Api.fetchCurrentUser)

    yield Eff.put(Login.receivedUser(currentUser))

    const {
      unreadMessages,
      unreadNotifications,
    } = yield Eff.call(Api.fetchMessagesNum)
    yield Eff.put(MessageList.receivedMessagesNum({ unreadMessages, unreadNotifications }))
  } catch (error) {
    yield Eff.put(Login.failure())
  }
}

export function* fetchCustomPageData({ payload: { publicUri, teleExpertiseType } }) {
  try {
    const type = { avis: 'opinion', staff: 'staff', cohorte: 'cohort' }
    const {
      [type[teleExpertiseType]]: {
        id,
        name,
        description,
        pictureFileName,
        thePersonal,
        ownerProfilePicture,
        tags,
        disciplines,
        isVideoconferencingToday,
        owner,
        nextSessionStartDate,
        portalHeaderImageName: portalHeaderImage,
        portalHeaderImageAlign,
        portalHeaderImageStretch,
        portal,
      }
    } = yield Eff.call(Api.fetchTeleExpertiseFromCode, type[teleExpertiseType], publicUri)

    yield Eff.put(Login.selectTeleExpertise({
      teleExpertiseType: type[teleExpertiseType],
      teleExpertiseId: id,
      teleExpertiseName: name,
      teleExpertiseDescription: description,
      teleExpertisePicture: pictureFileName,
      thePersonal: thePersonal,
      teleExpertiseTags: tags,
      teleExpertiseOwner: `${owner.title} ${owner.firstname} ${owner.lastname}`,
      teleExpertiseOwnerProfilePicture: (Number(thePersonal) && ownerProfilePicture && ownerProfilePicture.replace(/.*\//, '')),
      teleExpertiseDisciplines: disciplines,
      teleExpertiseIsVisioToday: isVideoconferencingToday,
      staffNextSessionDate: nextSessionStartDate,
      portalHeaderImage: portalHeaderImage,
      portalHeaderImageAlign,
      portalHeaderImageStretch,
      portalDocuments: portal.attachements || [],
      portalAbout: portal.description || '',
    }))

    yield Eff.put(Patient.updateField({
      name: 'expertises',
      value: {
        type: `${type[teleExpertiseType]}s`,
        id,
        name,
      }
    }))

    yield Eff.put(Login.success())

  } catch (error) {
    yield Eff.put(Login.error(error.message))
  }
}

export function* logOut() {
  try {
    const data = yield Eff.call(Api.isLogged)

    if (data.success) {
      yield Eff.call(Api.logOut)
    }

    window.location = `${process.env.REACT_APP_SKEZI_AUTH_FRONT_URL}/logout`
  } catch (error) {
    yield Eff.put(Login.error(error.errorMessage))
  }
}

export function* checkNewDeployment() {
  const lastDeploymentDatetime = yield Eff.call(Api.fetchLastDeploymentDatetime)
  if (!lastDeploymentDatetime.isUpdate) {
    localStorage.setItem('lastDeploymentDatetime', lastDeploymentDatetime.lastDeploymentDatetimeBack)
    window.location.reload()
  }
}

const LoginSagas = function* () {
  yield Eff.takeLatest(`${Login.sendCredentials}`, sendCredentials)
  yield Eff.takeLatest(`${Login.checkCode}`, checkCode)
  yield Eff.takeLatest(`${Login.resendCode}`, resendCode)
  yield Eff.takeLatest(`${Login.resetPassword}`, resetPassword)
  yield Eff.takeLatest(`${Login.fetchCurrentUser}`, fetchCurrentUser)
  yield Eff.takeLatest(`${Login.fetchCustomPageData}`, fetchCustomPageData)
  yield Eff.takeLatest(`${Login.logOut}`, logOut)
  yield Eff.takeLatest(`${Login.checkNewDeployment}`, checkNewDeployment)
}

export default LoginSagas;
