import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro'
import * as Login from '../State/Login'
import * as Ui from './Ui'
import * as Styled from './Login.styled'
import SEOTags from './SEOTags';
import { redirectToAuth } from '../Util/Auth'
import { privateSitePrefix } from '../Util/Config'

const LoginComponent = () => {
  const loading = useSelector(Login.selectLoading);
  const visioConference = useSelector(Login.selectedVisioconference);

  useEffect(() => {
    redirectToAuth(
      `${process.env.REACT_APP_PRIVATE_URL}${privateSitePrefix}/patients`,
      visioConference
    );
  }, [])

  return (
    <>
      <SEOTags
        title="Connectez-vous - Skemeet"
      />
      <Styled.LoginTeleExpertiseContainer>
        <Ui.PortailLayout.Wrapper>
          <Ui.PortailHeader noButtons />
          <Ui.PortailLayout.PortalMain $loading={loading}>
            <Ui.Global.Loader />
            {/* {loading
              ? <Ui.Global.Loader />
              : <>
                <Ui.PortailLayout.PortalContainer>
                  <Ui.PortailLayout.PortalContentContainer>
                    <Ui.PortailLayout.FlexPage>
                      <Ui.PortailLayout.Content>
                        <LoginForm />
                      </Ui.PortailLayout.Content>
                    </Ui.PortailLayout.FlexPage>
                  </Ui.PortailLayout.PortalContentContainer>
                  <Ui.PortailLayout.Footer />
                </Ui.PortailLayout.PortalContainer>
              </>
            } */}
          </Ui.PortailLayout.PortalMain>
        </Ui.PortailLayout.Wrapper>
      </Styled.LoginTeleExpertiseContainer>
    </>
  )
}

const ModalCloseButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-right: 1rem;
`

export default LoginComponent;
