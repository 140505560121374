import React, { useState } from 'react';
import * as ButtonUi from './Button';
import * as LayoutUI from './Layout';
import * as Ui from '.'
import * as IconUI from './Icon';
import { apiUrl } from '../../Util/Config'
import Logo from '../../Asset/Image/logo-shareconfrere.svg';
import LogoIcon from '../../Asset/Image/logo-shareconfrere-icon.svg';
import * as Login from '../../State/Login'
import * as PortalPro from '../../State/PortalPro'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { authUrl } from '../../Util/Config'

const PortalProHeaderComponent = ({ noButtons }) => {

  const [openConnectionBox, setOpenConnectionBox] = useState(false);
  const handleOpen = (value) => {
    setOpenConnectionBox(value)
  }
  const history = useHistory()
  const portalProId = useSelector(PortalPro.selectPortalProId)
  const portalProHost = useSelector(PortalPro.selectPortalProHost)
  const portalProLink = `/portail/${portalProHost}`
  const portalProLogo = useSelector(PortalPro.selectLogo)
  const portalProLogoUrl = portalProLogo ? `${apiUrl}${portalProLogo}` : null
  const portalProTitle = useSelector(PortalPro.selectTitle)
  const selectedExpertise = useSelector(Login.selectedExpertise)

  return (
    <Ui.PortailLayout.Header>
      {portalProTitle
        ? (
          <HeaderLeftContainer onClick={e => history.push(portalProLink)}>
            <HeaderLogoContainer>
              <HeaderLogo src={LogoIcon} alt="Shareconfrere" />
            </HeaderLogoContainer>
            <HeaderLeftTexteTitleContainer>
              <HeaderLeftTexteTitleSecondWord>{portalProTitle}</HeaderLeftTexteTitleSecondWord>
            </HeaderLeftTexteTitleContainer>
          </HeaderLeftContainer>
        )
        : (
          <a href={`${process.env.REACT_APP_AUTH_URL}`}>
            <img src={Logo} alt="Shareconfrere" />
          </a>
        )
      }
      {!noButtons &&
        <><MenuButton
          isGreen
          isFilled
          onClick={() => handleOpen(true)}>
          <IconUI.IconProfile />
        </MenuButton>
          <LayoutUI.HeaderButtons open={openConnectionBox}>
            <HeaderConnectionBoxLogo>
              {portalProTitle
                ? (
                  <HeaderLeftContainer onClick={e => history.push(portalProLink)}>
                    <HeaderLogoContainer>
                      <HeaderLogo src={portalProLogoUrl} alt="Shareconfrere" />
                    </HeaderLogoContainer>
                    <HeaderLeftTexteTitleContainer>
                      <HeaderLeftTexteTitleSecondWord>{portalProTitle}</HeaderLeftTexteTitleSecondWord>
                    </HeaderLeftTexteTitleContainer>
                  </HeaderLeftContainer>
                )
                : <img src={Logo} alt="Shareconfrere" />
              }
              <CloseButton onClick={() => handleOpen(false)}>
                <IconUI.IconClose />
              </CloseButton>
            </HeaderConnectionBoxLogo>
            <ButtonGroup>
              {/* <LinkNodeco href={`${process.env.REACT_APP_SKEZI_AUTH_FRONT_URL}/login?redirectUrl=${process.env.REACT_APP_PRIVATE_URL}/p/patients`}> */}
              <LinkNodeco href={`${authUrl}/connexion`}>
                <ButtonUi.BasicButton
                  isFilled
                  isGreen
                >
                  Se connecter
                </ButtonUi.BasicButton>
              </LinkNodeco>
              <LinkNodeco href={`${authUrl}/inscription`}>
                <ButtonUi.BasicButton
                  isFilled
                  isGreen
                >
                  S'inscrire
                </ButtonUi.BasicButton>
              </LinkNodeco>
            </ButtonGroup>
          </LayoutUI.HeaderButtons>
        </>
      }
    </Ui.PortailLayout.Header >
  )
}

const LinkNodeco = styled.a`
  text-decoration: none;
  @media only screen and (max-width: ${props => props.theme.device.tablet}) {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  ${ButtonUi.Button} {
    @media only screen and (max-width: ${props => props.theme.device.tablet}) {
      margin-right: 0;
      margin-left: 0;
      width: 100% ;
    }
  }
`

const MenuButton = styled(props => <ButtonUi.Button {...props} />)`
  font-size: 1.3rem;
  border-radius: 50px;
  letter-spacing: 0;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: none;
  padding: 0;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

const CloseButton = styled(props => <Ui.Icon.Icon {...props} />)`
  color: black;
  justify-self: flex-end;
  border: 0;
  cursor: pointer;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

const ButtonGroup = styled(props => <LayoutUI.ButtonGroup {...props} />)`
  @media only screen and (max-width: ${props => props.theme.device.tablet}) {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
`

const HeaderLeftContainer = styled.div`
  display: flex;
  cursor: pointer;
`

const HeaderLogoContainer = styled.div`
  margin-right: 1rem;
`

const HeaderLogo = styled.img`
  height: 3rem !important;
 // border-radius: 50%;
`

const HeaderLeftTexteTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const HeaderLeftTexteTitleSecondWord = styled(props => <Ui.Global.BlueText {...props} />)`
  font-size: 1.3rem;
  font-weight: bold;

  @media only screen and (max-width: ${props => props.theme.device.mobile}) {
    font-size: 1rem;
  }
`

const HeaderConnectionBoxLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

export default PortalProHeaderComponent;
